//import {Html5QrcodeScanner} from "html5-qrcode.min";
//console.info("Loaded myScipt.js");
	var theDataSource = "";
	var myTimeInt = false;

	var dataSetter = function() {
		this.init= function() {
	
			//waitDataTable();

		}
		
		this.setDataTable = function(obj,msg = ""){
			if(msg){console.warn(msg,obj);}
			//theDataSource = obj;
			anon();
		}
		
		this.setAutocomplete = function(arrays){
				autocomplete(document.getElementById("designation"), arrays);
		}

	}

	var myTimer;
	
	function anon(){
		//console.info("Loaded - myScipt.js");
		myTimer = setInterval(waitDataTable,1000);
	};
	
	//Check if the table exist
	//and wait for data loaded on table
	function waitDataTable(){
		
		let id = "#dataTable";
		let target = $(document).find(id);
		let tr = $(target).find('tr');
		
		if($(tr).length>=2){
			//set dataTable on data Loaded
			if(!$(id).is('.initiate')){
				$(id).addClass('initiate');
				
				if($(target).is('.hasFilter')){
					myFilterSetter(id);	
				}
				setDataTable(id);
			}else{
				//console.warn('Already Initiate', "STOP setting DataTable");
				
			}
			clearInterval(myTimer);
		}
		//clearInterval(myTimer);
	}
	
/*********************************************************************************/	
	
	function setDataTable(id,parms = ""){
		let target = $(document).find(id);
		
		 table = $(target).DataTable({
			search: {
           	 return: false,
        	},
			/*order: [[0, 'desc']],*/
			paging: true,
			ordering: true,
			info: true,
			lengthMenu : [20,50,100],
			language: {
				lengthMenu: 'Afficher _MENU_ lignes par page',
				zeroRecords: 'Ligne introuvable',
				info: 'Affichage de _PAGE_ sur _PAGES_ pages',
				infoEmpty: 'Aucun résultat',
				infoFiltered: '(filter sur _MAX_ lignes)',
				search: 'Recherche rapide'
				
        	}
		}).draw();
	}

/********************************************************************************/
	
	function autocomplete(inp, arr) {
		
		let currentFocus;
		
		inp.addEventListener("input", function(e) {
			let a, b, i, val = this.value;
			
			val = val.toUpperCase();
			closeAllLists();
			
			if (!val) { return false;}
			
			currentFocus = -1;
			
			a = document.createElement("DIV");
			a.setAttribute("id", this.id + "autocomplete-list");
			a.setAttribute("class", "autocomplete-items");
			
			this.parentNode.appendChild(a);
			
			for (i = 0; i < arr.length; i++) {
				
				//if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
				if (arr[i].includes(val)) {
					b = document.createElement("DIV");
					
					let text;
					text = arr[i].replace(val,"<strong>"+val+"</strong>");
					
					/*b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";*/
					/*b.innerHTML += arr[i].substr(val.length);*/
					b.innerHTML +=  text;

					b.innerHTML += '<input type="hidden" value="' + arr[i] + '">';
					
					b.addEventListener("click", function(e) {
						
						inp.value = this.getElementsByTagName("input")[0].value;
						inp.focus();
						//inp.setAttribute('ng-reflect-model',inp.value);
						
						closeAllLists();
					});
					
					a.appendChild(b);
				}
			}
		});
		
		inp.addEventListener("keydown", function(e) {
			let x = document.getElementById(this.id + "autocomplete-list");
			
			if(x) x = x.getElementsByTagName("div");
			
			if(e.keyCode == 40) { //Down
				
				currentFocus++;
				addActive(x);
			}else if (e.keyCode == 38) { //up
				currentFocus--;
				addActive(x);
			}else if (e.keyCode == 13) { //Enter
				e.preventDefault();
					if (currentFocus > -1) {
						if (x) x[currentFocus].click();
					}
			}
		});
		
		function addActive(x) {
			if (!x) return false;

			removeActive(x);
			if (currentFocus >= x.length) currentFocus = 0;
			if (currentFocus < 0) currentFocus = (x.length - 1);
			x[currentFocus].classList.add("autocomplete-active");
		}
		
		function removeActive(x) {
			for (let i = 0; i < x.length; i++) {
				x[i].classList.remove("autocomplete-active");
			}
		}
		
		function closeAllLists(elmnt) {
			let x = document.getElementsByClassName("autocomplete-items");
			for (let i = 0; i < x.length; i++) {
				if (elmnt != x[i] && elmnt != inp) {
					x[i].parentNode.removeChild(x[i]);
				}
			}
		}
		
		document.addEventListener("click", function (e) {
			closeAllLists(e.target);
		});
}

